import axios from 'axios'
import {Message} from 'element-ui';

// const baseApi = 'http://localhost:8456/';
const baseApi = 'https://yywl.yunyuwuliu.com:8455/';
const miniApi = 'https://www.yunyuwuliu.com/mini/';
export default class AxiosUtils {
    static baseApi = baseApi;
    static miniApi = miniApi;

    static get(url, params, data) {
        return this.getPromise('get', url, params, data);
    }

    static post(url, params, data) {
        return this.getPromise('post', url, params, data);
    }

    static upload(url, params, data) {
        return this.getPromise('post', url, params, data, true);
    }

    static getPromise = (type, url, params, data, needHeader) => {
        return new Promise((resolve, reject) => {
            axios({
                url: url,
                method: type,
                headers: needHeader ? {'Content-Type': 'multipart/form-data'} : '',
                baseURL: baseApi,
                withCredentials: true,
                timeout: 10000,
                params,
                data,
            }).then((res) => {
                if (res.data.code === 200) {
                    resolve(res.data.data);
                    if (res.data.msg && res.data.msg !== 'success') {
                        Message.success(res.data.msg);
                    }
                } else {
                    if (res.data.code === 203) {
                        sessionStorage.setItem('loginInfo', null);
                        window.location.href = '/login'
                    }
                    Message.error(res.data.msg);
                    reject(res.data.data);
                }
            }).catch((err) => {
                Message.error('服务器连接失败');
                reject(err)
            })
        })
    };
}