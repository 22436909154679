import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import moment from "moment"
import './element-variables.scss'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import axiosUtils from "./utils/axiosUtils";
import myUtils from "./utils/myUtils";

// Vue.use(Viewer) //默认配置写法
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
    toolbar: true
  } })

Vue.use(ElementUI, {locale});
Vue.prototype.moment = moment;

Vue.config.productionTip = false
Vue.prototype.axiosUtils = axiosUtils;
Vue.prototype.myUtils = myUtils;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
