export default {

    getUserName() {
        let name = '';
        let user = JSON.parse(sessionStorage.getItem('user'));
        if (user) {
            if (user.name) {
                name = user.name
            }
        }
        return name
    },

    getUserCompany() {
        let company = 0;
        let user = JSON.parse(sessionStorage.getItem('user'));
        if (user) {
            if (user.idCompany) {
                company = user.idCompany
            }
        }
        return company
    },

    getLoginID() {
        let userID = 0;
        let user = JSON.parse(sessionStorage.getItem('user'));
        if (user) {
            userID = user.id;
        }
        return userID
    },

    getLoginInfo() {
        return JSON.parse(sessionStorage.getItem('user'));
    },

    //生成从minNum到maxNum的随机数
    randomNum(minNum, maxNum) {
        switch (arguments.length) {
            case 1:
                return parseInt(Math.random() * minNum + 1, 10);
            case 2:
                return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
            default:
                break;
        }
    },

    getSysSetting() {
        return JSON.parse(sessionStorage.getItem('setting'));
    },

    getDefaultURL() {
        let list = JSON.parse(sessionStorage.getItem('list'))
        for (let i = 0; i < list.length; i++) {
            if (list[i].menName.indexOf('process') !== -1) {
                console.log(list[i].menName)
                return list[i].menName;
            }
        }
    },

    getPassword: () => new Date().getTime() * 3 + 438548,

    isIdCard: (cardNo) => {
        let idCardReg =
            /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        return idCardReg.test(cardNo)
    }
}