import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export const constantRouterMap = [
    {
        path: '/',
        redirect: '/login',
        hidden: true,
    },
    {
        path: '/login',
        component: () => import('@/views/login/login'),
        hidden: true,
    },
    {
        path: '/taskList',
        component: () => import('@/views/layout/layout'),
        children: [ {
            path: '/taskList',
            name: '/taskList',
            component: () => import('@/views/task/taskList'),
        }, {
            path: '/setting',
            name: '/setting',
            component: () => import('@/views/setting/setting'),
        },{
            path: '/userList',
            name: '/userList',
            component: () => import('@/views/user/userList'),
        },]
    }
];

export default new Router({
    mode: 'history',
    routes: constantRouterMap,
})
