<template>
  <div id="app" class="contains">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }

  .contains {
    min-width: 1200px;
  }

</style>
